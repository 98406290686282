import * as React from 'react';
import clsx from 'clsx';
/**
 * A tiny component to abstract away pretty-checkbox "state" div.
 * Shared by all components.
 */
export const State = ({ color, icon, id, children, ...rest }) => {
    return (React.createElement("div", { className: clsx('state', color && `p-${color}`), ...rest },
        icon,
        React.createElement("label", { htmlFor: id }, children)));
};
State.displayName = 'State';
