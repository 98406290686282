const lockedStyles = { pointerEvents: 'none' };
/**
 * A simplier way to prevent checking when PCR is in locked mode.
 * This is way simplier than controlling the checkbox using
 * event handlers. Supports IE 11+
 */
export const useLocked = ({ locked, style }) => {
    if (locked) {
        return {
            ...lockedStyles,
            ...style,
        };
    }
    return style;
};
