const isDefault = (animation, type) => {
    if (type) {
        return false;
    }
    return !animation || animation === 'smooth' || animation === 'pulse';
};
/**
 * Responsible for returning an object used by classnames
 * to properly add the className values to the .pretty element.
 */
export const useClassNames = (props, isSwitch) => {
    const { animation, bigger, locked, plain, shape, variant, iconType, hasFocus } = props;
    return {
        'p-default': !isSwitch && isDefault(animation, iconType),
        'p-bigger': bigger,
        'p-locked': locked,
        'p-plain': plain,
        'p-has-focus': hasFocus,
        [`p-${animation}`]: animation,
        [`p-${shape}`]: shape,
        [`p-${variant}`]: variant,
        [`p-${iconType}`]: iconType,
    };
};
