import * as React from 'react';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';
import { useCheckboxState } from './useCheckboxState';
import { useLocked } from '../../hooks/utility/useLocked';
import { useIcon } from '../../hooks/useIcon';
import { useClassNames } from '../../hooks/utility/useClassNames';
import { useControlled } from '../../hooks/utility/useControlled';
import { useCheckboxRadioProps } from '../../hooks/utility/useCheckboxRadioProps';
import { State } from '../state/State';
import { useIndeterminate } from './useIndeterminate';
export const Checkbox = React.forwardRef((props, ref) => {
    const { checked, value, state, ...rest } = useControlled(props);
    const { children, locked, color, id, className, style, indeterminate, icon: propsIcon, htmlProps, } = useCheckboxRadioProps(rest);
    const { ref: intRef, ...aria } = useIndeterminate({ state, checked, indeterminate });
    const styles = useLocked({ locked, style });
    const { icon, iconType } = useIcon(propsIcon);
    return (React.createElement("div", { style: styles, className: clsx('pretty', useClassNames({
            ...props,
            iconType,
        }), className) },
        React.createElement("input", { ref: mergeRefs([ref, intRef]), value: value, type: "checkbox", id: id, checked: checked, ...aria, ...htmlProps }),
        React.createElement(State, { id: id, icon: icon, color: color }, children)));
});
Checkbox.displayName = 'Checkbox';
export { useCheckboxState };
