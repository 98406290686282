import * as React from 'react';
const handler = (e) => {
    e.currentTarget.setAttribute('aria-checked', e.currentTarget.checked + '');
};
/**
 * A small hook to help manage correct aria-checked state when switch
 * is used as an uncontrolled component. We need this to run
 * for a11y purposes. FOr the `switch` role, `aria-checked` is required.
 */
export const useAriaChecked = ({ setState, checked }) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        const elem = ref.current;
        let bound = false;
        if (!setState && !checked && elem) {
            elem.setAttribute('aria-checked', elem.checked + '');
            elem.addEventListener('change', handler);
            bound = true;
        }
        return () => {
            if (bound && elem) {
                elem.removeEventListener('change', handler);
            }
        };
    }, [setState, checked]);
    return ref;
};
