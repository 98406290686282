import * as React from 'react';
import clsx from 'clsx';
import { State } from '../state/State';
import { useLocked } from '../../hooks/utility/useLocked';
import { useIcon } from '../../hooks/useIcon';
import { useClassNames } from '../../hooks/utility/useClassNames';
import { useControlled } from '../../hooks/utility/useControlled';
import { useCheckboxRadioProps } from '../../hooks/utility/useCheckboxRadioProps';
import { useRadioState } from './useRadioState';
export const Radio = React.forwardRef((props, ref) => {
    const { checked, value, state, ...rest } = useControlled(props);
    const { shape = 'round', children, locked, color, id, className, style, icon: propsIcon, htmlProps, } = useCheckboxRadioProps(rest);
    const styles = useLocked({ locked, style });
    const { icon, iconType } = useIcon(propsIcon);
    return (React.createElement("div", { style: styles, className: clsx('pretty', useClassNames({
            ...props,
            shape,
            iconType,
        }), className) },
        React.createElement("input", { ref: ref, value: value, type: "radio", id: id, checked: checked, ...htmlProps }),
        React.createElement(State, { id: id, icon: icon, color: color }, children)));
});
Radio.displayName = 'Radio';
export { useRadioState };
