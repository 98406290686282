import { useUUID } from '../useUUID';
/**
 * Filters out the noise of prop mayhem with PCR. This separates
 * all props from the native HTML props that will eventually get passed
 * to the input element. Generated PCR UUIDs are created here, unless
 * user has specified one of their own.
 */
export const useCommonProps = (props) => {
    const defaultId = useUUID();
    const { locked, color, variant, animation, children, style, id = defaultId, className, bigger, hasFocus, ...rest } = props;
    return {
        locked,
        color,
        variant,
        animation,
        children,
        id,
        className,
        bigger,
        style,
        hasFocus,
        htmlProps: rest,
    };
};
