import * as React from 'react';
import clsx from 'clsx';
import { useClassNames } from '../../hooks/utility/useClassNames';
import { useControlled } from '../../hooks/utility/useControlled';
import { useLocked } from '../../hooks/utility/useLocked';
import { State } from '../state/State';
import { useCommonProps } from '../../hooks/utility/useCommonProps';
import mergeRefs from 'react-merge-refs';
import { useAriaChecked } from './useAriaChecked';
export const Switch = React.forwardRef((props, ref) => {
    const { checked, value, state, ...rest } = useControlled(props);
    const { children, locked, color, id, className, style, htmlProps } = useCommonProps(rest);
    const styles = useLocked({ locked, style });
    const htmlRef = useAriaChecked({ setState: props.setState, checked });
    return (React.createElement("div", { style: styles, className: clsx('pretty', 'p-switch', useClassNames(props, true), className) },
        React.createElement("input", { ref: mergeRefs([ref, htmlRef]), type: "checkbox", role: "switch", value: value, id: id, "aria-checked": checked, checked: checked, ...htmlProps }),
        React.createElement(State, { id: id, color: color }, children)));
});
Switch.displayName = 'Switch';
